// Colors:
$main-font-color: #828282;
$main-blue-color: #F1F5F9;
$secondary-blue-color: #DBE9F9;
$main-green-color: #009681;
$main-green-color-light: #C0E7E2;
$main-green-color-disabled: #D9F1EE;
$main-green-color-dark: #008270;
$secondary-green-color: #BCCE00;
$secondary-green-color-dark: #A2B100;
$secondary-green-color-disabled: #edf780;
$tertiary-green-color: #D7FFBF;
$info-green-color: #49C5B1;
$info-green-color-dark: #43b7a3;
$main-red-color: #f20000;
// $main-red-color: #f3c7c1;
$main-red-color-hover: #d20303;
$main-red-color-disabled: #ee7a7a;
$main-gray-color: #787878;
$secondary-gray-color: #CCCCCC;
$main-white-color: #FFFFFF;
$main-black-color: #1D1D1B;

// Font relative sizes
$font-relative-size-xs: 0.8em;
$font-relative-size-sm: 1em;
$font-relative-size-md: 1.2em;
$font-relative-size-lg: 1.5em;
$font-relative-size-xl: 2em;