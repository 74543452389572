@use 'src/global-variables';
// @import '~primeng/resources/themes/saga-blue/theme.css';
@import '~primeng/resources/themes/lara-light-blue/theme.css';
@import '~primeng/resources/primeng.min.css';
@import "primeicons/primeicons.css";


* {
    box-sizing: border-box;
}
html {
    background-color: global-variables.$main-blue-color;
}
body, h1, h2 {
    padding: 0px;
    margin: 0;
    font-family: 'Inter', sans-serif;
}
html, body { height: 100%; }

:host ::ng-deep {
    .dropdown {
        border-radius: 20px;
    }
}

:host ::ng-deep .p-datatable-tbody > tr > td {
    padding: 0.5rem;
}

.p-datatable-loading-overlay {
    color: global-variables.$main-white-color;
    z-index: 1;
}



@media screen and (min-width: 1300px) {
    :host ::ng-deep .p-datatable-tbody > tr > td {
        padding: 1rem;
    }
}
